/**
 * Component: Navbar
 */

$bc-navbar-small-height         : 3.2rem !default;
$bc-navbar-height               : 4.8rem !default;
$bc-navbar-large-height         : 5.6rem !default;
$bc-navbar-xlarge-height         : 8rem !default;

.bc-navbar {
  padding: 0 2.4rem;
  height: $bc-navbar-height;
  width: 100%;
  display: flex;
  align-items: center;
}

.bc-navbar > .bc-toolbar {
  flex: 1 0 auto;
}

.bc-navbar--white {
  background: $white;
}

.bc-navbar--fixed {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1001;
  border-bottom: 1px solid $border;
}

.bc-navbar--scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  position: static;
}

/* Bordered variants
  ========================================================================== */

.bc-navbar--bordered-top {
  border-top: 0.1rem solid $border;
}

.bc-navbar--bordered-bottom {
  border-bottom: 0.1rem solid $border;
}

/* Sizes
  ========================================================================== */

.bc-navbar--large {
  height: $bc-navbar-large-height;
}

.bc-navbar--small {
  height: $bc-navbar-small-height;
}

.bc-navbar--xlarge {
  height: $bc-navbar-xlarge-height;
}

.bc-navbar--auto {
  height: auto;
}
