#flash-messages {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column-reverse;
    background-color: transparent;

    .bc-toast {
        margin: 0 1.3rem 1.3rem 0;
    }
}