//
// Additions to Bootstrap `_badge.scss`
//

.badge {
  height: 2.4rem;
  display: inline-flex;
  align-items: center;
  line-height: 2.4rem;
  min-width: 2rem;

  // Fix Safari
  -webkit-appearance: none
}

.badge .if {
  font-size: 1.6rem;
}

.if + .badge-text,
.badge-text + .if,
.badge-value + .if,
.bc-avatar + .badge-text,
.badge-text + .bc-avatar {
  padding-left: .6rem;
}

.c-counter + .badge-text,
.badge-text + .c-counter {
  margin-left: .4rem;
}

.badge .bc-avatar:first-child {
  margin-left: -.8rem;
}

.badge-value {
  opacity: 0.85;
}

.badge .if:last-child,
.badge .bc-avatar:last-child {
  margin-right: -.6rem;
}

.badge .if:first-child,
.badge .bc-avatar:first-child {
  margin-left: -.6rem;
}

.badge-lg {
  height: 2.8rem;
}

.badge-circle {
  width: .6rem;
  height: .6rem;
  border-radius: .8rem;
  display: block;
  margin-right: 0.6rem;
}

.badge-primary {
  background-color: $primary;
  color: $white;
}

.badge-default {
  background-color: $blue-50;
  color: $blue-600;

  .badge-circle {
    background: $blue-500;
  }
}

.badge-secondary,
a.badge-secondary {
  background-color: $gray-400;
  color: $white;
}

.badge-light {
  background-color: $gray-50;
  color: $text-muted;
}

.badge-success-light {
  background-color: $green-50;
  color: $green-600;

  .badge-circle {
    background: $green-500;
  }
}

.badge-danger-light {
  background-color: $red-50;
  color: $red-600;

  .badge-circle {
    background: $red-500;
  }
}

.badge-warning-light {
  background-color: $orange-50;
  color: $orange-600;

  .badge-circle {
    background: $orange-500;
  }
}

.badge-outline-primary {
  color: $primary;
  border: 1px solid $primary;
}

.badge-outline-secondary {
  border: .1rem solid $border-color;
  background-color: $white;
  color: $gray-600;
}

.badge-list {
  margin: 0 -0.2rem;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;

  .badge {
    margin: 0.2rem;
  }
}

.badge-sm {
  height: 2rem;
  font-size: 1.2rem;
  padding: 0.3rem 0.6rem;
}

.badge-sm .if:last-child,
.badge-sm .bc-avatar:last-child {
  margin-right: -.2rem;
}
