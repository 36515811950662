/**
 * Component: Toast
 * ===
 */

 .bc-toast {
  background-color: $gray-900;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  border-radius: .3rem;
  height: 5.6rem;
  width: 36rem;

  .bc-toast__message {
    margin: 0;
    padding-left: 1.6rem;
  }

  .bc-toast__left {
    display: flex;
    align-items: center;
  }

  .bc-toast__right .btn-link {
    color: rgba($white, .5);
  }
}

.bc-toast--success .bc-toast__left i {
  color: $success;
}

.bc-toast--error .bc-toast__left i {
  color: $danger;
}

.bc-toast--info .bc-toast__left i {
  color: $blue;
}

.bc-toast--warning .bc-toast__left i {
  color: $orange;
}
