//
// Additions to Bootstrap cards
//

.card {
  box-shadow: 0 .2rem .2rem 0 rgba($gray-500, .12);
}

.card-header {
  height: 5.6rem;
  display: flex;
  align-items: center;
  font-weight: 500;

  .bc-toolbar-title {
    font-size: 1.6rem;
  }

  .btn {
    z-index: 1000;
  }
}

.card-body {
  padding: $card-spacer-y $card-spacer-x;
}

.card-title {
  font-size: 1.6rem;
}

.card-footer {
  height: 6.4rem;
  display: flex;
  align-items: center;
}

.card-collapsible .collapse-trigger[aria-expanded="false"] {
  border-bottom: none;
}

.card-collapsible .collapse-trigger[aria-expanded="true"] {
  border-bottom: .1rem solid $border;
}

.card-collapsible .collapse-trigger[aria-expanded="true"]~.if-chevron-right,
.card-collapsible .collapse-trigger[aria-expanded="true"]~.bc-toolbar .if-chevron-right {
  transform: rotate(90deg);
}

.card-collapsible .collapse-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-collapsible .card-header {
  border: none;
  position: relative;
}

.card-body,
.card-body .table-responsive {
  // Padding for tables inside of cards
  >.table tr td:first-child,
  >.table tr th:first-child {
    padding-left: 2.4rem;
  }

  >.table tr td:last-child,
  >.table tr th:last-child {
    padding-right: 2.4rem;
  }

  // Fix bordered tables within cards
  >.table-bordered tr td:first-child,
  >.table-bordered tr th:first-child {
    border-left: none;
  }

  >.table-bordered tr td:last-child,
  >.table-bordered tr th:last-child {
    border-right: none;
  }

  >.table-bordered tr:last-child td {
    border-bottom: none;
  }
}

.card--secondary {
  .card-header {
    background: none;
  }

  background: $gray-25;
  mix-blend-mode: multiply;
}

.card--primary,
.card--success,
.card--warning,
.card--danger {
  position: relative;
  &::before {
    content:'';
    position: absolute;
    width: 0.3rem;
    height: 100%;
    top: 0;
    left:0;
  }
}

.card--primary::before {
    background-color: $primary;
}

.card--success::before {
    background-color: $success;
}

.card--danger::before {
    background-color: $danger;
}

.card--warning::before {
    background-color: $warning;
}

.card-deck {
  width: calc(100% - 1.6rem);
  border: 1px solid $border;
  border-top: 0;
  border-radius: 0 0 8px 8px;
  padding: 0.6rem 0;
  margin: 0 auto;
  background-color: #FFF;
  box-shadow: 0 .2rem .2rem 0 rgba($gray-500, .12);
}

.card--dashed {
  box-shadow: none;
  background-color: transparent;
  border: 1px dashed $gray-300;
}
