/* ==========================================================================
    Component: Divider
   ========================================================================== */

.c-divider {
  background-color: $border-color;
  height: .1rem;
  max-width: 100%;
  margin: 2.4rem 0
}
