//
// Additions to Bootstrap pagination
//

.pagination {
  margin-bottom: 0;
}
.pagination .page-item {
  .page-link {
    width: 3.2rem;
    height: 3.2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
