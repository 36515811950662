/* ==========================================================================
  Component: Button Toolbar
  ---
  Group multiple buttons together
   ========================================================================== */

.c-button-toolbar {
  align-items: center;
  display: flex;

  .btn {
    margin: 0.4rem 1.2rem 0.4rem 0;
  }

  .btn:last-child {
    margin-right: 0;
  }
}


.c-button-toolbar--wide-spacing {
  .btn {
    margin: 0.4rem 1.6rem 0.4rem 0;
  }

  .btn:last-child,
  .dropdown:last-child .btn {
    margin-right: 0;
  }
}

.c-button-toolbar--vertical {
  flex-direction: column;
  align-items: flex-end;
  .btn {
    margin: 0.4rem 0.4rem 0.8rem 0.4rem;
  }
}
