/* ==========================================================================
  Colors
  Only define colors here. Don't use sass color functions, stick to hex values,
  or the color component in styleguide won't regenerate automatically anymore.
 ========================================================================== */

/* Grays
 ========================================================================== */

 $gray-25: #FAFAFA;
 $gray-50: #F2F2F2;
 $gray-100: #E6E6E6;
 $gray-150: #DEDEDE;
 $gray-200: #CCCCCC;
 $gray-300: #B4B4B4;
 $gray-400: #999999;
 $gray-500: #808080;
 $gray-550: #737373;
 $gray-600: #646464;
 $gray-700: #4D4D4D;
 $gray-800: #333333;
 $gray-900: #202020;
 $gray-1000: #1A1A1A;
 $white: #FFF;
 $black: #000;


 /* Blue
 ========================================================================== */

 $blue-50: #E9F0FA;
 $blue-100: #D5E3F6;
 $blue-200: #A8C7F0;
 $blue-400: #4E8EE4;
 $blue-500: #3E7EDA;
 $blue-600: #1E64C8;
 $blue-700: #174482;
 $blue-800: #132E53;
 $blue-900: #09172A;

 /* Green
 ========================================================================== */

 $green-50: #F1F7ED;
 $green-100: #E3F0DB;
 $green-200: #C5DEBA;
 $green-400: #8ABE74;
 $green-500: #71A860;
 $green-600: #4E8B41;
 $green-700: #3A6831;
 $green-800: #23471F;
 $green-900: #11240F;

 /* Red
 ========================================================================== */

 $red-50: #FDECE7;
 $red-100: #FCD9CF;
 $red-200: #F7B4A1;
 $red-500: #DC4E28;
 $red-600: #BC3510;
 $red-700: #8D280C;
 $red-800: #601906;
 $red-900: #300D03;

 /* Orange
 ========================================================================== */

 $orange-50: #FEF7E7;
 $orange-100: #FDEFCE;
 $orange-200: #FADB9E;
 $orange-400: #F4B857;
 $orange-500: #F1A42B;
 $orange-600: #DA840B;
 $orange-700: #915808;
 $orange-800: #623804;
 $orange-900: #311C02;


 /* Primary
 ========================================================================== */

 $blue: #1E64C8;
 $dark-blue: #003FA3;
 $light-blue: #E9F0FA;

 $yellow: #FFD200;
 $dark-yellow: #E5B400;
 $light-yellow: #FFFAE5;

 $gray: #282F36;
 $dark-gray: #101820;

 /* Secondary
 ========================================================================== */

 $green: #71A860;
 $dark-green: #528448;
 $light-green: #F0F6EF;
 $warm-red: #DC4E28;
 $dark-warm-red: #B8381E;
 $light-warm-red: #FBEEE9;
 $orange: #F1A42B;
 $dark-orange: #D28914;
 $light-orange: #FDF5E7;
 $turquoise: #27ABAD;
 $aqua: #2D8CA8;
 $grey-blue: #6F71B9;
 $purple: #825491;
 $light-purple: #BE5190;
 $salmon-pink: #E85E71;
 $warm-orange: #FB7E3A;

 /* Semantic
 ========================================================================== */

 $warning: #F1A42B;
 $success: #71A860;
 $danger: #DC4E28;

 $text: #4D4D4D;
 $header: #1A1A1A;
 $text-muted: #737373;

 $border: #DEDEDE;
 $icon-muted: #999999;
 $icon-default: #666666;
