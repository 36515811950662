// Start your CSS here
@import "colors";
@import "icon-font";
@import "settings.bootstrap";
@import "br-variables-override";

/* Bootstrap upgrade (todo organise into file)
   ========================================================================== */

// Functions come first
@import "bootstrap/scss/functions";

// Optional variable overrides here
$custom-color: #1e64c8;
$custom-theme-colors: (
  "custom": $custom-color,
);

// Variables come next
@import "bootstrap/scss/variables";

// Optional Sass map overrides here
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

// Followed by our default maps
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";

// Rest of our imports
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

/* Generic: CSS reset, box-sizing, global CSS tweaks
  ========================================================================== */

//@import "_reset";

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/forms/input-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/bootstrap-utilities";

// Bootstrap additions
@import "./bootstrap-additions/alerts";
@import "./bootstrap-additions/background-variant";
@import "./bootstrap-additions/badges";
@import "./bootstrap-additions/borders";
@import "./bootstrap-additions/breadcrumb";
@import "./bootstrap-additions/button-groups";
@import "./bootstrap-additions/buttons";
@import "./bootstrap-additions/cards";
@import "./bootstrap-additions/dropdown";
@import "./bootstrap-additions/forms";
@import "./bootstrap-additions/modal";
@import "./bootstrap-additions/inputs";
@import "./bootstrap-additions/list-group";
@import "./bootstrap-additions/nav";
@import "./bootstrap-additions/pagination";
@import "./bootstrap-additions/popover";
@import "./bootstrap-additions/progress";
@import "./bootstrap-additions/reboot";
@import "./bootstrap-additions/stacks";
@import "./bootstrap-additions/table";
@import "./bootstrap-additions/toasts";
@import "./bootstrap-additions/tooltip";

/* Bootstrap custom components (based on Bootstrap variables)
   ========================================================================== */
@import "./bootstrap-custom/bc-avatar";
@import "./bootstrap-custom/bc-navbar";
@import "./bootstrap-custom/bc-toast";
@import "./bootstrap-custom/bc-toolbar";

// Custom components written from scratch

@import "./components/c-activity-list";
@import "./components/c-author";
@import "./components/c-bullet";
@import "./components/c-button-toolbar";
@import "./components/c-comment";
@import "./components/c-content";
@import "./components/c-counter";
@import "./components/c-divider";
@import "./components/c-dl";
@import "./components/c-blank-slate";
@import "./components/c-file-upload";
@import "./components/c-flatpickr";
@import "./components/c-meta-list";
@import "./components/c-progress-bar";
@import "./components/c-publication-card";
@import "./components/c-radio-card";
@import "./components/c-sidebar";
@import "./components/c-stepper";
@import "./components/c-side-panel";
@import "./components/c-sub-sidebar";
@import "./components/c-tagify";
@import "./components/c-thumbnail";

/* Third-party additions
   ========================================================================== */
// Currently unused

// @import "select2/src/scss/core";
// @import "vendor/select2-bootstrap4-theme/select2-bootstrap4.scss";

/* Custom utilities written from scratch
   ========================================================================== */

@import "./utilities/u-generic";

html {
  font-size: 62.5%; // Base 10
}

main {
  width: 100%;
  height: calc(100% - 3.2rem);
  // max-height: calc(100% - 3.2rem);
  overflow: hidden;
  background-color: $gray-25;
}

h1,
h2,
h3,
h4 {
  color: $header;
}

h1 {
  line-height: 1.2;
}

h2 {
  line-height: 1.3;
}

h3 {
  line-height: 1.4;
}

h4 {
  line-height: 1.5;
}

.if {
  font-size: 2rem;
  line-height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.if--small {
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.if--large {
  font-size: 3.2rem;
  line-height: 3.2rem;
}

.if--success {
  color: $success;
}

.if--error {
  color: $danger;
}

.if--primary {
  color: $blue;
}

.if--warning {
  color: $warning;
}

.if--danger {
  color: $danger;
}

.if--muted {
  color: $icon-muted;
}
