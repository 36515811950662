/* ==========================================================================
    Component: Bullet
   ========================================================================== */

.c-bullet {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: $blue;
}
