/* ==========================================================================
    Component: Comment
   ========================================================================== */

.c-comment {
  background: rgba($light-blue, 0.6);
  border: 1px solid rgba($blue, 0.2);
  color: mix($blue, $text, 30%);
  border-radius: .3rem;
  margin-top: 2rem;
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;

  .bc-avatar-and-text {
    flex: 1;
  }
}

.c-comment-meta {
  color: mix($blue, $text-muted, 32%);
  font-size: 1.2rem;
}

.c-comment-title {
  color: mix($blue, $header, 32%);
  font-weight: 500;
  font-size: 1.2rem;
}

.c-comment-text {
  flex: 1;
}
