//
// Additions to Bootstrap dropdown links
//

.dropdown-item {
  text-decoration: none;
  display: flex;
  align-items: center;

  > .if {
    padding-right: 1.2rem;
  }
}

.dropdown-footer {
  padding: 0 1.6rem;
}

.dropdown-menu {
  z-index: 100000 !important;
  min-width: 30rem;
}

.dropdown-menu__body {
  max-height: 30vh;
  overflow-y: auto;
  padding: 1.4rem 2.4rem 0.8rem 2.4rem;
}

.dropdown-item-check {
  display: none;
}

.dropdown-toggle {
    //Fix safari
    -webkit-appearance: none;
}

.dropdown-item--selected {
  background-color: $gray-50;

  .dropdown-item-check {
    display: block;
    margin-left: 1.2rem;
    flex: 1;
    text-align: end;
  }
}

.btn-sm.dropdown-toggle {
  &::after {
    margin-left: 0.2rem;
    border-top: 0.3rem solid;
    border-right: 0.3rem solid transparent;
    border-left: 0.3rem solid transparent;
  }
}
