@font-face {
  font-family: "icon-font";
  src:
    // Path is relative starting from /assets/css/app.scss
    url("../ugent/fonts/icon-font.woff2") format("woff2"),
    url("../ugent/fonts/icon-font.woff") format("woff");
}

i[class^="if-"]:before,
i[class*=" if-"]:before {
  font-family: icon-font !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.if-add:before {
  content: "\f101";
}
.if-alert-fill:before {
  content: "\f102";
}
.if-alert:before {
  content: "\f103";
}
.if-arrow-down:before {
  content: "\f104";
}
.if-arrow-go-back:before {
  content: "\f105";
}
.if-arrow-left:before {
  content: "\f106";
}
.if-arrow-right:before {
  content: "\f107";
}
.if-arrow-up:before {
  content: "\f108";
}
.if-article:before {
  content: "\f109";
}
.if-bar-chart:before {
  content: "\f10a";
}
.if-book:before {
  content: "\f10b";
}
.if-calendar-1:before {
  content: "\f10c";
}
.if-calendar:before {
  content: "\f10d";
}
.if-caret-down:before {
  content: "\f10e";
}
.if-caret-left:before {
  content: "\f10f";
}
.if-caret-right:before {
  content: "\f110";
}
.if-caret-up:before {
  content: "\f111";
}
.if-cc-by-line:before {
  content: "\f112";
}
.if-cc-line:before {
  content: "\f113";
}
.if-cc-nc-line:before {
  content: "\f114";
}
.if-cc-nd-line:before {
  content: "\f115";
}
.if-cc-sa-line:before {
  content: "\f116";
}
.if-cc-zero-line:before {
  content: "\f117";
}
.if-check-circle-fill:before {
  content: "\f118";
}
.if-check-circle:before {
  content: "\f119";
}
.if-check-double:before {
  content: "\f11a";
}
.if-check:before {
  content: "\f11b";
}
.if-chevron-down:before {
  content: "\f11c";
}
.if-chevron-left:before {
  content: "\f11d";
}
.if-chevron-right:before {
  content: "\f11e";
}
.if-chevron-up:before {
  content: "\f11f";
}
.if-close-circle-fill:before {
  content: "\f120";
}
.if-close:before {
  content: "\f121";
}
.if-copy:before {
  content: "\f122";
}
.if-database:before {
  content: "\f123";
}
.if-delete:before {
  content: "\f124";
}
.if-double-quotes:before {
  content: "\f125";
}
.if-download:before {
  content: "\f126";
}
.if-draft-fill:before {
  content: "\f127";
}
.if-draft:before {
  content: "\f128";
}
.if-drag:before {
  content: "\f129";
}
.if-edit:before {
  content: "\f12a";
}
.if-empty:before {
  content: "\f12b";
}
.if-error-circle-fill:before {
  content: "\f12c";
}
.if-error-circle:before {
  content: "\f12d";
}
.if-external-link:before {
  content: "\f12e";
}
.if-eye-fill:before {
  content: "\f12f";
}
.if-eye-off:before {
  content: "\f130";
}
.if-eye:before {
  content: "\f131";
}
.if-feedback-fill:before {
  content: "\f132";
}
.if-file-pdf:before {
  content: "\f133";
}
.if-file-text:before {
  content: "\f134";
}
.if-file-word:before {
  content: "\f135";
}
.if-file:before {
  content: "\f136";
}
.if-forbid:before {
  content: "\f137";
}
.if-ghent-university:before {
  content: "\f138";
}
.if-global:before {
  content: "\f139";
}
.if-grid:before {
  content: "\f13a";
}
.if-home:before {
  content: "\f13b";
}
.if-info-circle-filled:before {
  content: "\f13c";
}
.if-info-circle:before {
  content: "\f13d";
}
.if-list-check:before {
  content: "\f13e";
}
.if-list-unordered:before {
  content: "\f13f";
}
.if-lock-unlock:before {
  content: "\f140";
}
.if-lock:before {
  content: "\f141";
}
.if-log-out:before {
  content: "\f142";
}
.if-mail:before {
  content: "\f143";
}
.if-menu:before {
  content: "\f144";
}
.if-message:before {
  content: "\f145";
}
.if-messages:before {
  content: "\f146";
}
.if-more:before {
  content: "\f147";
}
.if-move-bottom:before {
  content: "\f148";
}
.if-move-top:before {
  content: "\f149";
}
.if-no-edit:before {
  content: "\f14a";
}
.if-notification:before {
  content: "\f14b";
}
.if-orcid:before {
  content: "\f14c";
}
.if-pin:before {
  content: "\f14d";
}
.if-request-change:before {
  content: "\f14e";
}
.if-search:before {
  content: "\f14f";
}
.if-service:before {
  content: "\f150";
}
.if-settings:before {
  content: "\f151";
}
.if-share:before {
  content: "\f152";
}
.if-start:before {
  content: "\f153";
}
.if-subtract:before {
  content: "\f154";
}
.if-table:before {
  content: "\f155";
}
.if-time:before {
  content: "\f156";
}
.if-user-add:before {
  content: "\f157";
}
.if-user:before {
  content: "\f158";
}
.if-zoom-in:before {
  content: "\f159";
}
