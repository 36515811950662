//
// Additions to Bootstrap `_background-variant.scss`
//

.bg-danger-light {
  background: $light-warm-red;
}

.bg-lightest {
  background-color: $gray-25;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-light-green {
  background-color: $light-green;
}

.bg-yellow {
  background-color: #fef5c8;
}

.bg-dark-blue {
  background-color: $blue-900;
}

.bg-dark-gray {
  background-color: $dark-gray;
}
