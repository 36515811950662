//
// Additions to Bootstrap nav
//

button.nav-link {
  appearance: none;
  background-color: transparent;
  border: none;
}

.nav.nav-main {
  flex-wrap: nowrap;

  .nav-link {
    color: $text;
    display: flex;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 1.6rem;
    font-size: 1.2rem;
    border-right: 1px solid $border;
  }

  .nav-item:last-child .nav-link {
    border: none;
  }

  @include media-breakpoint-down(md) {
    .nav-link > .btn-text,
    .nav-link > .bc-avatar-and-text .bc-avatar-text {
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }
}

.nav.nav-list {

  .nav-item {
    padding: 0.6rem 0;
  }

  .nav-link {
    display: inline-flex;
    border-bottom: 1px solid $primary;
    padding: 0;
  }
}

.nav.nav-pills {
  .nav-link {
    font-weight: 500;
    padding: 0.8rem 0;
    color: $text-muted;
    display: flex;
    align-items: center;
    position: relative;

    /*
    &::before {
      content:'';
      position: absolute;
      width: 0.1rem;
      height: 100%;
      top: 0;
      left:0;
      background: $border;
    }*/

    &.active {
      color: $primary;
      background-color: transparent;

      .c-subline,
      h1,h2,h3,h4 {
        color: $primary;
      }
      /*
      &::before {
        width: 0.3rem;
        background: $primary;
      }*/
    }
  }
}

.dropdown .nav.nav-pills .nav-link {
  padding: 0.8rem 1.6rem;
}

.nav.nav-sidebar {
  .nav-link {
    font-weight: 500;
    padding: 0.8rem 1.6rem;
    color: $text-muted;
    display: flex;
    align-items: center;

    &.active {
      color: $primary;
      background-color: $gray-100;
      }
  }
}

.nav.nav-tabs {
  margin-bottom: -0.1rem;

  .nav-text {
    max-width: 18rem;
  }

  .nav-link{
    color:  $text-muted;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: none;
    background: none;
    border-bottom: 2px solid transparent;
    margin-right: 2.4rem;
    font-weight: 500;
    color: $text-muted;
    height: 4.8rem;

    .c-counter {
      margin-left: .8rem;
    }

    &:hover,
    &:focus {
      border-bottom: 2px solid $primary;
    }

    &.active {
      border-bottom: 2px solid $primary;
      color: $primary;
    }

    &.disabled {
      color: $gray-200;
    }
  }
}

.nav-tabs-scrollable {
  overflow: auto;
  flex-wrap: nowrap;
  .nav-link {
    white-space: nowrap;
  }
}
