/* ==========================================================================
    Component: Radio card
   ========================================================================== */

.c-radio-card {
  border: .1rem solid $border;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem;
  margin: 0;
  font-weight: normal;

  &:hover,
  &:focus {
    border-color: $blue;
    box-shadow: 0 0 0 3px $light-blue;
  }

  .form-check-label {
    height: 2.2rem;
  }
}

.c-radio-card--selected {
  border-color: $blue;
  box-shadow: 0 0 0 3px $light-blue;
  background-color: $gray-25;
}

.c-radio-card__radio {
  margin-right: 1.1rem;
}

.c-radio-card + .c-radio-card {
  margin-top: 0.8rem;
}

.c-radio-card__hidden-content {
  display: none;
}

.c-radio-card--selected .c-radio-card__hidden-content {
  display: block;
  padding-left: 7.2rem;
  margin-top: .8rem;
}
