.u-maximize-height {
  height: 100% !important;
}

.u-scroll-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.u-scroll-wrapper__body {
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
  height: 100%;
}

.u-inner-content {
  // calculate: 100% height - toolbar height
  height: calc(100% - 3.2rem) !important;
}

.u-section {
  margin-top: 1.5rem;
}

.u-hidden {
  display: none;
}

.u-horizontal-scroll {
  overflow-x: auto;
  overflow-y: auto;
}

.u-min-w-750 {
  min-width: 75rem;
}


.u-max-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 2.25rem;
  max-height: 4.5rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.u-no-transition {
  transition: none !important;
}

.u-divide-x {
   > :not([hidden])~:not([hidden]) {
    border-left: 1px solid $border;
  }
}

.u-min-w-0 {
  min-width: 0;
}

.u-z-reset {
  z-index: auto;
}

.u-max-w-720 {
  max-width: 72rem;
}

.u-smooth-scroll {
  scroll-behavior: smooth;
}

.u-mix-blend-multiply {
  mix-blend-mode: multiply;
}

.u-border-light {
  border-color: $gray-100 !important;
}

.u-min-w-8 {
  min-width: 8rem;
}

.u-min-w-auto {
  min-width: auto;
}