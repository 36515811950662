//
// Additions to Bootstrap modals
//

/* Headers/footer
   ========================================================================== */

.modal-footer {
  height: 6.4rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}

.modal-header {
  height: 6.4rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}

.modal-footer > * {
  margin: 0;
}

.modal-fullscreen,
.modal-dialog-scrollable.modal-fullscreen {
  max-height: none;
  max-width: none;
  height: calc(100% - 4.8rem);
  width: calc(100% - 4.8rem);
  margin: 1.5rem 2rem;
  .modal-content {
    border-radius: 0.8rem;
  }
}

.modal-fullscreen {
  .modal-content,
  .modal-body {
    height: 100%;
  }
}

.modal-dialog,
.modal-open {
  overflow-y: hidden;
}

.modal-content {
  max-height: calc(100vh - 6.4rem);
}

.modal-header,
.modal-footer {
  flex: 0 0 auto;
}
